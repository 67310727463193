import * as React from "react";
import { graphql } from "gatsby";
// import { useTranslation } from "react-i18next";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Article from "../components/Article";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const TemplateSection = ({ data, pageContext }) => {
  // const { t } = useTranslation("sections");
  const title = data.mdx.frontmatter.title;
  let settings = Object.assign({}, data.mdx.frontmatter.settings);
  settings["showTitle"] =
    typeof settings["showTitle"] === "undefined" ||
    settings["showTitle"] === null
      ? true
      : settings["showTitle"];
  settings["showToc"] =
    typeof settings["showToc"] === "undefined" || settings["showToc"] === null
      ? true
      : settings["showToc"];
  settings["showTocScroll"] =
      typeof settings["showTocScroll"] === "undefined" || settings["showTocScroll"] === null
        ? false
        : settings["showTocScroll"];

  const showToc = settings.showToc &&
    data.mdx.tableOfContents &&
    Object.keys(data.mdx.tableOfContents).length

  return (
    <Layout>
      <Seo title={title} />
      <div>
        <div>
          {data.mdx ? (
            <Article
              title={title}
              showTitle={settings.showTitle && title}
              showToc={showToc}
              showTocScroll={showToc && settings.showTocScroll}
              tableOfContents={data.mdx.tableOfContents}
            >
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </Article>
          ) : (
            <div>This page hasn't been translated yet.</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TemplateSection;

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        title
        slug
        settings {
          showTitle
          showToc
          showTocScroll
        }
      }
      body
      tableOfContents
    }
  }
`;
